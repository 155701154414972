body {
  margin: 0;
  overflow: hidden;
}

#three-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: none; /* Prevent default touch actions such as scrolling */
}

@media (max-width: 768px) {
  #three-container {
    width: 100vw;
    height: 100vh;
  }
}
